import type { IFetchError } from 'ofetch'

interface Data {
	status?: number
	name?: string
	type?: string
	message?: string
	fatal?: boolean
	signout?: boolean
	ignore?: boolean
	persistent?: boolean
	button?: {
		label: string
		action(): void
	}
}

export const useFetchError = <T extends IFetchError<Data>>(error: T) => {
	const alert = useAlertStore()

	const getters = {
		data: {
			status: error?.statusCode || 500,
			name: 'Error',
			message: error?.message,
			type: 'error',
			...error?.data ?? {},
		},
	}

	const actions = {
		throw: () => createError(getters.data),
		alert: async () => {
			const error = await parseError(getters.data)
			if (error?.message) alert.create(error)
		},
		fullscreen: () => {
			const error = useError()
			error.value = createError(getters.data)
		},
	}

	return {
		...getters,
		...actions,
	}
}

const parseError = async (error: Data) => {
	const err = { ...error }

	if (error?.ignore) {
		delete err.message
		delete err.ignore
	}
	if (error?.signout) {
		delete err.signout
		const auth = useAuthStore()
		await auth.logout()
	}

	return err as Alert
}
