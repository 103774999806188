import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from '#i18n'
import type { I18n } from '#i18n'
import type { NuxtApp } from '#app'

export default defineNuxtPlugin({
	name: 'vuetify:i18n',
	dependsOn: ['i18n:plugin', 'vuetify:locale'],
	parallel: true,
	setup(nuxt) {
		const useGlobalI18n = <T extends Record<string, any>>(nuxt: T | NuxtApp) => {
			return { global: nuxt.$i18n } as I18n<any, NonNullable<unknown>, NonNullable<unknown>, string, false>
		}

		nuxt.hook('vuetify:config', (options, module) => {
			if (!module?.i18n) return

			const i18n = useGlobalI18n(nuxt)
			const { locale, locales, localeCodes } = i18n.global

			options.locale ||= {}
			options.locale.locale = locale.value ?? module?.locale?.default
			options.locale.adapter = createVueI18nAdapter({ i18n, useI18n })

			// Set rtl config
			options.locale.rtl = locales.value
				.reduce((locales, locale) => {
					locales[locale.code] = locale.dir === 'rtl'
					return locales
				}, <Record<string, boolean>>{})

			// Set locales in date
			options.date ||= {}
			options.date.locale = localeCodes.value.reduce((locales, code) => {
				locales[code] = code
				return locales
			}, <Record<string, string>>{})
		})
	},
})
