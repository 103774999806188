type Counter = number | string | null

const key = 'counter'
export const useCounterStore = defineStore(key, () => {
	const account = useAuthStore()
	const { count, set, reset, inc, dec, get } = useCounter(account?.value?.patients ?? 0)

	const actions = {
		set: (counter: Counter) => {
			set(Number(counter))
			if (account?.value?.patients) account.value.patients = count.value
			return count.value
		},
		inc: (delta?: number) => {
			inc(delta)
			if (account?.value?.patients) account.value.patients = count.value
			return count.value
		},
		dec: (delta?: number) => {
			dec(delta)
			if (account?.value?.patients) account.value.patients = count.value
			return count.value
		},
		reset: (counter?: number) => {
			reset(counter)
			if (account?.value?.patients) account.value.patients = count.value
			return count.value
		},
		get,
	}

	return {
		value: count,
		...actions,
	}
})
