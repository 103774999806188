import Adapter from '@date-io/dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'

import type { DateIOFormats } from '@date-io/core/IUtils'
import type { PluginFunc, Dayjs } from 'dayjs'

type ConstructorParams<T> = T extends abstract new(args: infer P) => any ? P : never
type Options = ConstructorParams<typeof Adapter> & {
	weekStart?: number
	timeZone?: string
}

type DateParameter = string | number | Date | Dayjs | null | undefined
type ClockParameter = {
	hour?: number
	minute?: number
	second?: number
}
interface DateFormats extends DateIOFormats<string> {
	weekdayAndDate: string
	standard: string
}

export class DateAdapter extends Adapter {
	private plugin: Record<string, boolean>
	weekStart?: number
	timeZone?: string

	declare readonly formats: DateFormats

	constructor(options: Options) {
		super(options)
		this.plugin = {}
		this.weekStart = options.weekStart ?? 0
		this.timeZone = options.timeZone

		this.setupFormats()
		this.setWeekStart(this.weekStart)
	}

	private update = () => {
		const dayjs = this.rawDayJsInstance
		const locale = this.locale || dayjs.locale()
		this.dayjs = (!locale ? dayjs : (...args: Parameters<typeof dayjs>) => dayjs(...args).locale(locale))
	}

	public toNow = (value?: DateParameter, withoutSuffix?: boolean | undefined) => {
		this.registerPlugin(relativeTime, 'relativeTime', 'to')

		const date = this.withLocale()
		const relative = date.to(value, withoutSuffix)
		return relative.charAt(0).toUpperCase() + relative.slice(1)
	}

	private registerPlugin<T>(plugin: PluginFunc<T>, key: string, prop?: string) {
		const _prop = prop || key
		this.plugin[key] = _prop in this.rawDayJsInstance.prototype

		if (!this.plugin[key]) {
			this.rawDayJsInstance.extend(plugin)
			this.update()
		}
	}

	public fromNow = (value?: DateParameter, withoutSuffix?: boolean | undefined) => {
		this.registerPlugin(relativeTime, 'relativeTime', 'fromNow')

		const date = this.withLocale(value)
		const relative = date.fromNow(withoutSuffix)
		return relative.charAt(0).toUpperCase() + relative.slice(1)
	}

	public from = (value?: DateParameter, reference?: DateParameter, withoutSuffix?: boolean | undefined) => {
		this.registerPlugin(relativeTime, 'relativeTime', 'from')
		const date = this.withLocale(value)
		const relative = date.from(reference, withoutSuffix)
		return relative.charAt(0).toUpperCase() + relative.slice(1)
	}

	// @ts-ignore
	public date = (value?: DateParameter) => this.withLocale(value)

	private withLocale(date?: DateParameter) {
		const locale = this.locale || 'en'
		this.rawDayJsInstance.tryLocale(locale)
		return this.withTimeZone(date).locale(locale)
	}

	private setupFormats = () => {
		this.formats.weekdayAndDate = 'dddd DD'
		this.formats.standard = 'YYYY-MM-DD'
	}

	public utc = (date?: DateParameter, keepLocalTime?: boolean | undefined) => {
		this.registerPlugin(utc, 'utc')
		return this.withLocale(date)?.utc(keepLocalTime)
	}

	public getTime = (date?: DateParameter) => {
		return this.withLocale(date).toDate().getTime()
	}

	override format = (date: DateParameter, format: keyof DateFormats) => {
		const $format = this.formats[format] || format
		return this.withLocale(date).format($format)
	}

	override formatByString = (date: DateParameter, format: string) => {
		return this.withLocale(date).format(format)
	}

	override addMinutes = (date: DateParameter, quantity: number) => {
		return this.withLocale(date).add(quantity, 'minutes')
	}

	override formatNumber = (numberToFormat: string | number, format?: string) => {
		const timestamp = Number(numberToFormat)
		return this.withLocale(timestamp).format(format)
	}

	public setLocale = (locale?: string) => {
		this.rawDayJsInstance.tryLocale(locale)
		this.locale = locale
		return this.locale
	}

	override getWeekdays = (firstDayOfWeek?: number, format: 'short' | 'middle' | 'large' = 'short') => {
		this.registerPlugin(localeData, 'localeData', 'weekdays')
		this.setWeekStart(firstDayOfWeek)

		const weekdays = this.rawDayJsInstance.weekdays(true)
		return weekdays.map(word => resizeAndCapitalize(word, format))
	}

	override getWeekArray = (date: DateParameter, firstDayOfWeek?: number) => {
		this.setWeekStart(firstDayOfWeek)

		const start = this.startOfWeek(this.startOfMonth(date))
		const end = this.endOfWeek(this.endOfMonth(date))

		let count = 0
		let current = start
		const weeks: Dayjs[][] = []

		while (this.isBefore(current, end)) {
			const week = Math.floor(count / 7)
			weeks[week] = weeks[week] || []
			weeks[week].push(current)

			current = this.addDays(current, 1)
			count += 1
		}

		return weeks
	}

	public getMonths = (format: 'short' | 'middle' | 'large' = 'short'): string[] => {
		this.registerPlugin(localeData, 'localeData', 'months')

		const months = this.rawDayJsInstance.months()
		return months.map(word => resizeAndCapitalize(word, format))
	}

	public isToday = (date: DateParameter) => {
		return this.withLocale(date).isSame(new Date(), 'day')
	}

	public isMoorning = (date: DateParameter) => this.withLocale(date).hour() < 12

	public isAfternoon = (date: DateParameter) => {
		const hour = this.withLocale(date).hour()
		return hour >= 12 && hour < 18
	}

	public isEvening = (date: DateParameter) => this.withLocale(date).hour() >= 18

	override isSameDay = (date: DateParameter, reference: DateParameter) => {
		return this.withLocale(date).isSame(reference, 'day')
	}

	override startOfDay = (date?: DateParameter) => this.withLocale(date).startOf('day')
	override endOfDay = (date?: DateParameter) => this.withLocale(date).endOf('day')
	override startOfMonth = (date?: DateParameter) => this.withLocale(date).startOf('month')
	override endOfMonth = (date?: DateParameter) => this.withLocale(date).endOf('month')
	override startOfYear = (date?: DateParameter) => this.withLocale(date).startOf('year')
	override endOfYear = (date?: DateParameter) => this.withLocale(date).endOf('year')
	public setClock = (date: DateParameter, options: ClockParameter = {}) => {
		let value = this.withLocale(date)
		if (options?.hour) value = value.hour(options.hour)
		if (options?.minute) value = value.minute(options.minute)
		if (options?.second) value = value.second(options.second)
		return value
	}

	public getNextYear = (date: DateParameter) => this.withLocale(date).add(1, 'year').year()

	public setWeekStart = (weekStart?: number | string) => {
		this.registerPlugin(updateLocale, 'updateLocale')

		const numeric = Number(weekStart || 0)
		const locale = this.withLocale().locale()
		this.rawDayJsInstance.updateLocale(locale, { weekStart })
		this.weekStart = numeric
		return this.weekStart
	}

	public getTimeZones = () => Intl.supportedValuesOf('timeZone')

	private withTimeZone = (date?: DateParameter) => {
		this.registerPlugin(timezone, 'tz')
		return this.dayjs(date).tz(this.timeZone)
	}

	public getTimeZone = (date?: DateParameter, timeZone?: string, keepLocalTime?: boolean) => {
		this.registerPlugin(timezone, 'tz')

		const zone = timeZone || this.timeZone || this.getCurrentTimeZone()
		return this.withLocale(date).tz(zone, keepLocalTime)
	}

	public getCurrentTimeZone = () => {
		this.registerPlugin(timezone, 'tz')
		return this.rawDayJsInstance.tz.guess()
	}

	public setTimeZone = (timeZone?: string) => {
		this.registerPlugin(timezone, 'tz')
		this.timeZone = timeZone ?? this.getCurrentTimeZone()
		// return this.rawDayJsInstance.tz.setDefault(timeZone)
	}
}

const resizeAndCapitalize = (word: string, format: 'short' | 'middle' | 'large' = 'short') => {
	const firstChar = word.charAt(0).toUpperCase()
	switch (format) {
		case ('short'): return firstChar
		case ('middle'): return firstChar + word.slice(1, 3)
		default: return firstChar + word.slice(1)
	}
}

export const createDateAdapter = (options: Partial<Options>) => {
	return new DateAdapter(options)
}
