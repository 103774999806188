export const useUiStore = defineStore('iu', () => {
	const { t } = useNuxtI18n()

	// State
	const state = reactive<State>({
		layout: useLocalStorage<Direction>('layout', () => 'horizontal'),
		slideover: {
			status: {
				active: false,
			},
			slot: {
				header: false,
				footer: false,
			},
			props: {
				width: 360,
				order: 1,
				color: 'background',
				location: 'left',
			},
		},
		section: {
			expanded: useLocalStorage('section:expand', () => ({})),
		},
		policy: {
			cookie: useLocalStorage('policy:cookie', () => ({
				active: true,
				analytics: false,
			}),
			),
			privacy: {
				active: false,
			},
		},
		online: useOnline(),
	})

	// Getters
	const getters = {
		layouts: computed(() => {
			const path = 'direction.'
			const layouts: Layout['value'][] = ['discord', 'github']

			return layouts.map((value) => {
				const key = value === 'discord' ? 'vertical' : 'horizontal'
				return {
					title: t(path + key),
					icon: key === 'vertical' ? '$layoutVertical' : '$layoutHorizontal',
					value,
				}
			})
		}),
		layout: computed({
			get() {
				if (state.layout === 'vertical') return 'discord'
				return 'github'
			},
			set(value) {
				state.layout = value === 'discord' ? 'vertical' : 'horizontal'
			},
		}),
	}

	const actions: Actions = {
		openSlideover(options) {
			if (options) state.slideover = { ...state.slideover, ...options }
			state.slideover.status.active = true
			return this.closeSlideover
		},
		closeSlideover: () => {
			state.slideover.status.active = false
		},
	}

	return {
		...toRefs(state),
		...getters,
		...actions,
	}
})

interface Layout {
	title: string
	value: 'discord' | 'github'
}

type Direction = 'vertical' | 'horizontal'

interface SlideOver {
	status: {
		active: boolean
	}
	slot: {
		header: boolean
		footer: boolean
	}
	props: {
		width?: number | string
		order?: number | string
		temporary?: boolean
		floating?: boolean
		permanent?: boolean
		location: 'top' | 'left' | 'right' | 'bottom' | 'start' | 'end'
		color?: string
	}
	// open(options?:this):() => void
	// close(): void
}

interface Actions {
	openSlideover(options?: SlideOver): () => void
	closeSlideover(): void
}

interface CookiePolicy {
	active: boolean
	analytics: boolean
}

interface State {
	layout: Ref<Direction>
	slideover: SlideOver
	policy: {
		cookie: Ref<CookiePolicy>
		privacy: {
			active: boolean
		}
	}
	section: {
		expanded: Ref<Record<string | number | symbol, boolean>>
	}
	online: Ref<boolean>
}
