import type { Credentials } from 'google-auth-library'

type Credential = {
	email: string
	psw: string
}
type SignInCredential = Credential & {
	remember?: boolean
}
type SignUpCredential = {
	user: string
	email: string
	psw: string
	pswConfirm: string
}
type CredentialRecover = PickFrom<SignInCredential, ['psw', 'pswConfirm']> & {
	token: string
}
type GCredential = Credentials & {
	credential?: string
}

const key = 'authentication'
export const useAuthStore = defineStore(key, () => {
	const alert = useAlertStore()
	const io = useSocketIOStore()
	const { t } = useNuxtI18n()

	const active = useLocalStorage('active', () => null)

	// State
	const state = reactive<State>({
		valid: false,
		value: null,
		active,
	})

	// Getters
	const getters = {
		account: computed({
			get: () => {
				const active = state.value?.partners?.find(({ id }) => state.active === id)
				if (state?.value && !active) state.active = state.value?.id
				return active || state?.value
			},
			set: (value) => { state.active = value?.id },
		}),
	}

	// Actions
	const actions = {
		withGoogle: async (credential: GCredential) => {
			state.value = await $fetch<User>('/api/auth/google/', {
				body: credential,
				method: 'POST',
				onResponse({ response }) {
					const valid = response.headers.get('x-session-active')
					state.valid = valid === 'true'

					if (!response.ok) return
					const path = state.valid ? '/:role()/home' : '/sign/tfa'
					navigateTo(path)
				},
			})
			return state.value
		},
		login: async (credential: SignInCredential) => {
			state.value = await $fetch<User>('/api/auth/signin', {
				body: credential,
				method: 'POST',
				onResponse({ response }) {
					const valid = response.headers.get('x-session-active')
					state.valid = valid === 'true'

					if (!response.ok) return
					const path = state.valid ? '/:role()/home' : '/sign/tfa'
					navigateTo(path)
				},
			})
			return state.value
		},
		register: (credential: SignUpCredential) => {
			return $fetch('/api/auth/signup', {
				body: credential,
				method: 'POST',
				async onResponse({ response }) {
					if (!response.ok) return
					await navigateTo({ name: 'sign-in' })
					const { email } = response._data
					alert.create({
						type: 'warning',
						persistent: true,
						message: t('sign.up.email', { email }),
						button: {
							label: t('actions.resend'),
							action: () => actions.sendValidation(email),
						},
					})
				},
			})
		},
		tfa: async (code: string) => {
			await $fetch<User>(`/api/auth/tfa/${code}`, {
				async onResponse({ response }) {
					if (!response.ok) return
					navigateTo('/:role()/home')
				},
			})
			return state.value
		},
		sendTfa: () => {
			return $fetch(`/api/auth/tfa/send`, {
				onResponse({ response }) {
					if (!response.ok) return
					const { email } = response._data
					alert.create({
						type: 'warning',
						persistent: true,
						message: t('sign.up.email', { email }),
					})
				},
			})
		},
		sendValidation: (email: string) => {
			return $fetch(`/api/auth/validation/send/${email}`, {
				onResponse({ response }) {
					if (!response.ok) return
					const { email } = response._data
					alert.create({
						type: 'warning',
						persistent: true,
						message: t('sign.up.email', { email }),
						button: {
							label: t('actions.resend'),
							action: () => actions.sendValidation(email),
						},
					})
				},
			})
		},
		forgotPsw: (email: string) => {
			return $fetch(`/api/auth/forgotpsw/${email}`, {
				onResponse({ response }) {
					if (!response.ok) return
					alert.create({
						type: 'info', icon: '$email',
						message: t('password.reset.email', { email }),
					})
				},
			})
		},
		resetPsw: (credential: CredentialRecover) => {
			return $fetch(`/api/auth/resetpsw/${credential.token}`, {
				body: credential,
				method: 'PATCH',
			})
		},
		logout: async () => {
			await $fetch('/api/auth/signout', { method: 'DELETE' })
			state.active = state.value = null
			reloadNuxtApp({ force: true })
		},
		check: (params: Partial<User>) => $fetch('/api/auth/availability', { params }),
	}

	// Websocket
	useSync('user', {
		patch: item => state.value = item,
		delete: () => actions.logout(),
	}, {
		key: `${key}:store`,
	})

	useSync('customer', {
		patch: (item) => {
			if (!state.value) return
			state.value.customer = item
		},
	}, {
		key: `${key}:store`,
	})

	return {
		...toRefs(state),
		...getters,
		...actions,
	}
})

interface State {
	valid?: boolean
	value?: Partial<User> | null
	active?: ReturnType<typeof useLocalStorage<string | null>>
}
