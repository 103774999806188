import createDynamicLocale from '../utils/createDynamicLocale'

export default defineNuxtPlugin({
	name: 'dayjs:dynamic-locales',
	async setup() {
		const { default: locales } = await import('#build/dayjs/locales.mjs')

		extendDayjsConfig((dayjs) => {
			const plugin = createDynamicLocale(locales)
			dayjs.extend(plugin)
		})
	},
})
