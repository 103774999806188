const key = 'alert'
export const useAlertStore = defineStore(key, () => {
	// State
	const state = reactive({
		value: new Map<string, Alert>(),
	})

	// Getters
	const getters = {
		length: computed(() => state.value.size),
	}

	// Actions
	const actions = {
		// Create alert
		create: (options: Alert) => {
			options.icon = options?.icon ?? setIcon(options)
			const id = options?.id ?? crypto.randomUUID()
			options.id = id
			options.active = true
			options.type = options.type ?? 'warning'
			options.props = options?.props || {}
			options.props.timeout = options?.persistent ? -1 : 5000

			options.close = () => {
				const item = state.value.get(id)
				if (item) item.active = false
				setTimeout(() => state.value.delete(id), 200)
			}

			if (options?.button) {
				const action = options?.button?.action
				options.button.action = () => {
					if (typeof action === 'function') action()
					if (options.button?.close !== false) options?.close!()
				}
			}

			state.value.set(id, options)
			return options.close
		},
		// Close alert
		close: (id: string) => { state.value.get(id)?.close!() },
	}

	return {
		...toRefs(state),
		...getters,
		...actions,
	}
})

const setIcon = (options: Partial<Alert>) => {
	switch (options?.type) {
		case 'warning':
			return '$alert'
		case 'error':
			return '$warning'
		case 'info':
			return '$info'
		case 'success':
			return '$complete'
		default:
			return undefined
	}
}
