const key = 'toast'
export const useToastStore = defineStore(key, () => {
	// State
	const state = reactive<State>({
		value: new Map(),
	})

	// Getters
	const getters = {
		length: computed(() => state.value.size),
	}

	// Actions
	const actions: Actions = {
		create: (options) => {
			const id = options.id ?? crypto.randomUUID()
			options.id = id
			options.active = true
			options.props = options?.props || {}
			options.props.timeout = options?.persistent ? -1 : 5000

			options.close = () => {
				const item = state.value.get(id)
				if (item) item.active = false
				setTimeout(() => state.value.delete(id), 200)
			}

			if (options?.button) {
				const action = options?.button?.action
				options.button.action = () => {
					if (typeof action === 'function') action()
					options?.close!()
				}
			}

			setTimeout(() => state.value.set(id, options), 300)
			return options.close
		},
		close: (id) => { state.value.get(id)?.close!() },
	}

	return {
		...toRefs(state),
		...getters,
		...actions,
	}
})

interface State {
	value: Map<string, Toast>
}

interface Actions {
	create(options: Toast): () => void
	close(id: string): void
}
