const configKey = 'tabId'
const tabKey = 'activeTab'

export default defineNuxtPlugin({
	name: 'one-browser-tab',
	parallel: true,
	enforce: 'post',
	setup(nuxt) {
		const prompt = usePromptStore()
		const runtime = useRuntimeConfig()
		if (!runtime.public.oneTabPerBrowser) return

		const { t } = useNuxtI18n()

		const uid = crypto.randomUUID()
		const local = useLocalStorage(tabKey, () => uid)
		const session = useSessionStorage(configKey, () => uid)

		nuxt.hook('app:mounted', () => {
			useHeadSafe({
				link: [{
					rel: 'icon',
					type: 'image/x-icon',
					href: () => local.value === session.value
						? '/favicon.ico'
						: '/error.ico',
				}],
			}, {
				mode: 'client',
			})
		})

		// Set confirm dialog
		const open = () => {
			prompt.create({
				id: 'tab:active',
				fullscreen: true,
				message: t('dialog.tab'),
				button: {
					action: () => {
						local.value = session.value
					},
				},
			})
		}

		nuxt.hook('app:beforeMount', () => {
			if (local.value && local.value !== session.value) open()

			if (import.meta.client) {
				window.onclose = () => {
					if (local.value === session.value) local.value = undefined
				}

				// on focus app
				// window.onblur = () => {
				// 	if (local.value && local.value !== session.value) open()
				// }

				window.onfocus = () => {
					if (local.value && local.value !== session.value) open()
				}
			}
		})
	},
})

declare global {
	interface Window {
		tabId?: string | null
	}
}
