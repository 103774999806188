import { useCookie } from '#imports'

export default defineNuxtPlugin({
	name: 'vuetify:theme',
	parallel: true,
	setup(nuxt) {
		nuxt.hook('vuetify:ready', (vuetify, module) => {
			const { theme } = module ?? {}
			if (theme?.storage === 'cookie' && theme?.key) {
				const cookie = useCookie(theme.key, { default: () => 'auto' })
				const { global, themes } = vuetify.theme
				const { name } = global

				const modes = Object.keys(themes.value)
				const exists = modes.includes(cookie.value)

				const is = window && 'matchMedia' in window
				const query = '(prefers-color-scheme: dark)'
				const { matches = false } = is ? window!.matchMedia?.(query) || {} : {}

				if (exists) name.value = cookie.value
				if (cookie.value === 'auto') {
					const value = modes.find(name => themes.value[name]?.dark === matches)
					name.value = value || name.value
				}
			}
		})
	},
})
