import type { useI18n } from '#i18n'

export default defineNuxtPlugin({
	name: 'dayjs:i18n',
	dependsOn: ['dayjs:config', 'i18n:plugin'],
	parallel: true,
	setup(nuxt) {
		const dayjs = useDayjs()
		const { locale }: ReturnType<typeof useI18n> = nuxt.$i18n
		watch(locale, value => dayjs.tryLocale(value))
	},
})
