export default defineNuxtPlugin({
	name: 'vuetify:date',
	dependsOn: ['vuetify:locale'],
	parallel: true,
	setup(nuxt) {
		nuxt.hook('vuetify:config', (options) => {
			delete options.blueprint?.date
		})

		nuxt.hook('vuetify:ready', (vuetify) => {
			const { date, locale } = vuetify
			if (!date.instance.locale) date.instance.locale = locale.current.value
			watch(locale.current, value => date.instance.locale = value)
		})
	},
})
