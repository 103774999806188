import { createFetchError } from 'ofetch'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export default defineNuxtPlugin((nuxt) => {
	const runtime = useRuntimeConfig()
	const { locale } = useNuxtI18n(nuxt)
	const auth = useAuthStore()

	const options: NitroFetchOptions<NitroFetchRequest> = {
		credentials: 'same-origin',
		onResponseError: (ctx) => {
			const $error = createFetchError(ctx)
			const { data, alert, fullscreen } = useFetchError($error.data)

			if (data?.fatal) fullscreen()
			else alert()
		},
		onRequest: ({ options }) => {
			options.headers = {
				...options.headers,
				'authorization': runtime.public.security.authorization,
				'x-account': auth.active || auth.value?.id || '',
				'accept-language': locale?.value || 'en',
			}
		},
	}

	const $fetch = globalThis.$fetch.create(options)
	globalThis.$fetch = $fetch
})
