import type { Pinia } from 'pinia'

export default defineNuxtPlugin(() => {
	const pinia: Pinia = usePinia()

	pinia?.use(({ store }) => {
		const clone = useCloneDeep(store.$state)
		store.$reset = (key?: ArrayLike<keyof typeof store.$state>) => {
			if (typeof key === 'string') store[key] = useCloneDeep(clone[key])
			else if (Array.isArray(key)) key.forEach(k => store[k] = useCloneDeep(clone[k]))
			else store.$patch(useCloneDeep(clone))
		}
	})
})

declare module 'pinia' {
	interface PiniaCustomProperties<Id, S> {
		$reset: (key?: ArrayLike<keyof S>, id?: Id) => void
	}
}
