import type { I18nHeadMetaInfo } from '@nuxtjs/i18n'
import { capitalize } from 'vue-demi'

export const useMetatags = async (head: I18nHeadMetaInfo) => {
	const app = useAppConfig()

	const { theme } = useVuetify()
	const { t } = useNuxtI18n()

	const { origin } = useRequestURL()
	const title = capitalize(app.name)

	// Seo
	useSeoMeta({
		title,
		description: () => t('app.description'),
		ogTitle: title,
		ogDescription: () => t('app.description'),
		ogImage: `${origin}/icons/favicon%20-%20adaptative.svg`,
		ogImageAlt: title,
		twitterTitle: title,
		twitterDescription: () => t('app.description'),
		twitterImage: `${origin}/icons/favicon%20-%20adaptative.svg`,
		twitterCard: 'summary',
	})

	// Head
	return useHead({
		htmlAttrs: {
			...head?.htmlAttrs,
		},
		meta: [
			{ name: 'theme-color', content: theme.current.value?.colors?.background },
			...(head?.meta || []),
		],
		link: [
			{ rel: 'icon', type: 'image/png', href: '/icons/maskable-icon-512x512.png' },
			...(head?.link || []),
		],
	})
}
