export default defineNuxtPlugin({
	name: 'vuetify:locale',
	parallel: true,
	setup(nuxt) {
		nuxt.hook('vuetify:config', (options, module) => {
			options.locale ||= {}
			options.locale.locale = module?.locale?.default
			delete options.blueprint?.locale
		})

		nuxt.hook('vuetify:ready', (vuetify, module) => {
			const { locale } = module ?? {}
			if (locale?.storage === 'cookie' && locale?.key) {
				const { current, fallback } = vuetify.locale
				const cookie = useCookie(locale.key, { default: () => current.value })
				if (current.value !== cookie.value) current.value = cookie.value || fallback.value
				watch(current, value => cookie.value = value)
			}
		})
	},
})
