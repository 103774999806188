type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE'
type Unsync = () => void

type Actions<T> = {
	[key in Lowercase<HTTPMethod>]?: (data: T) => void
} | ((data: T, method?: HTTPMethod) => void)

interface Options {
	key?: string
}

export function useSync<T = any>(event: string, actions: (data: T, method?: HTTPMethod) => void, options?: Options): Unsync
export function useSync<T = any>(event: string, actions: Actions<T>, options?: Options): Unsync
export function useSync<T>(...args: any[]) {
	const [event = '', actions = {}, options = {}] = args

	const io = useSocketIOStore()
	const close = io.on(event, (data, method: HTTPMethod) => {
		const items: T[] = Array.isArray(data) ? data : [data]

		for (const item of items) {
			const httpmethod = method.toLowerCase() as Lowercase<HTTPMethod>
			if (typeof actions === 'function') actions(item, method)
			else if (actions[httpmethod]) actions[httpmethod](item)
		}
	}, options?.key)

	return close
}
