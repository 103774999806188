export const useTimeStore = defineStore('time', () => {
	// State
	const state = reactive({
		clock12h: useLocalStorage('clock12h', () => true),
		weekStart: useLocalStorage('weekStart', () => 0),
		timeZone: useLocalStorage<string | undefined>('timeZone', () => undefined),
	})

	// Getters
	const { now } = useNow({ controls: true, interval: 5000 })

	const getters = {
		format: computed(() => state.clock12h ? 'fullTime12h' : 'fullTime24h'),
		fullFormat: computed(() => state.clock12h ? 'keyboardDateTime12h' : 'keyboardDateTime24h'),
		dateFormat: 'keyboardDate' as const,
		now: computed(() => now.value),
	}

	return {
		...toRefs(state),
		...getters,
	}
})
