import type { PluginFunc } from 'dayjs'

type LocaleMap = Map<string, () => Promise<any>>

export default (locales: LocaleMap) => {
	return <PluginFunc> function (option, Dayjs, dayjs) {
		dayjs.tryLocale = (locale) => {
			if (!locale) return dayjs.locale()
			if (locale && locales.has(locale)) {
				locales.get(locale)!()
					.then(() => dayjs.locale(locale))
					.catch(error => console.error(error))
			}
			return dayjs().clone()
		}
	}
}
