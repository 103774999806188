import type { Credentials } from 'google-auth-library'

const key = 'user'
export const useUserStore = defineStore(key, () => {
	const auth = useAuthStore()
	const alert = useAlertStore()
	const counter = useCounterStore()
	const { t } = useNuxtI18n()

	// State
	const state = reactive<State>({
		value: null,
	})

	// Getters
	const getters = {
		count: computed(() => auth.value?.patients ?? 0),
		contact: computed({
			get() {
				state.value = state.value ?? {}
				if (!state.value?.contact) state.value.contact = { socials: [] }
				return state.value?.contact
			},
			set(value) {
				state.value = state.value ?? {}
				state.value.contact = value
			},
		}),
	}

	// Actions
	const actions: Actions = {
		// Get user data
		fetch: async () => {
			const data = await $fetch<User>('/api/user/me', {
				onResponse: ({ response }) => {
					const valid = response.headers.get('x-session-active')
					const count = response.headers.get('x-counter')

					if (valid) auth.valid = valid === 'true'
					if (response.ok) counter.set(count)
					// !response.ok ? await auth.logout() : counter.set(count)
				},
			})
			auth.value = data
			return data
		},
		// Update user data
		update: async (user) => {
			const body = user ?? state.value
			const data = await $fetch<User>('/api/user', { body, method: 'PATCH' })
			auth.value = data
			return data
		},
		// Deactivate user
		delete: (credential) => {
			const body = credential
			return $fetch('/api/user', {
				body, method: 'DELETE',
				onResponse: ({ response }) => {
					if (response.ok) auth.logout()
				},
			})
		},
		// Check if user exist
		check: user => $fetch('/api/user/check', { params: user }),
		// Validate user email in token
		emailValidation: (token) => {
			return $fetch(`/api/user/newemailvalidation/${token}`, {
				method: 'PATCH',
				onResponse({ response }) {
					navigateTo({ name: 'role-home' })
					if (!response.ok) return
					alert.create({
						type: 'success',
						message: t!('email.validation'),
					})
				},
			})
		},
	}

	return {
		...toRefs(state),
		...getters,
		...actions,
	}
})

interface State {
	value: Partial<User> | null
}

interface Actions {
	fetch(): Promise<State['value']>
	update(user?: Partial<State['value']>): Promise<State['value']>
	check(user: Partial<User>): Promise<boolean>
	delete(credential: Credentials | { psw: string }): Promise<void>
	emailValidation(token: string): Promise<string>
}
