export function useOnlineNotification() {
	const { t } = useNuxtI18n()
	const ui = useUiStore()
	const toast = useToastStore()

	const id = 'notification:online'

	watch(() => ui.online, async (value) => {
		toast.close(id)

		toast.create({
			message: t(value ? 'alert.online' : 'alert.offline'),
			icon: value ? '$online' : '$offline',
			persistent: !value,
			id,
		})
	})
	return ui.online
}
