export default defineNuxtRouteMiddleware(async (to) => {
	const account = useAuthStore()

	if ('role' in to.params) {
		const role = to?.params?.role || null

		if (role !== account.value?.role) {
			to.params.role = account.value?.role || 'user'
			return await navigateTo(to)
		}
	}

	if (account.value?.role !== 'admin' && to.meta?.role === 'admin') {
		return await navigateTo('/:role()/home')
	}
})
