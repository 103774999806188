<template lang="pug">
nuxt-layout
	v-container.fill-height
		v-responsive.d-flex.align-center.fill-height
			v-card(
				max-width="64ch"
				:border="0"
				:rounded="0"
				variant="text"
				).mx-auto.text-center.d-flex.flex-column.ga-2.bg-beat-animation-error
				v-card-title.text-h2.font-weight-bold ERROR {{ $error?.statusCode }}
				v-card-text.text-subtitle-1.text-medium-emphasis {{ capitalize( $error?.message) }}

				v-card-actions.justify-center
					v-btn(@click="clear()" variant='outlined') {{ t('actions.reload') }}
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import { capitalize } from 'vue'

interface Props {
	error: NuxtError & {
		data: NuxtError & {
			data: Record<string, string>
		}
	}
}

const props = defineProps<Props>()
const { t } = useI18n()
const clear = () => {
	clearError()
	reloadNuxtApp({ force: true })
}

const $error = computed(() => {
	const { data, statusCode, statusMessage } = props?.error?.data ?? {}

	return {
		statusCode: statusCode ?? props?.error?.statusCode,
		statusMessage: statusMessage ?? props?.error?.statusMessage,
		name: data?.name ?? props?.error?.name,
		message: data?.message ?? props?.error?.message,
	}
})
</script>

<style lang="scss" scope>
@use '@/assets/scss/background';
</style>
