export default defineNuxtRouteMiddleware(async (to) => {
	const user = useUserStore()
	const auth = useAuthStore()

	// Check if route requieres authentication
	const requiresAuth = to?.matched.some(record => record.meta.requiresAuth)

	// Get user data
	if (!auth.value) {
		try {
			await callOnce('auth:user', () => user.fetch())
		}
		catch (error: any) {
			clearError()
			const $error = useFetchError(error?.data)
			$error.alert()
		}
	}

	// Validate navigation
	const isLogged = !!auth.value
	if (requiresAuth && isLogged) return true
	if (requiresAuth && !isLogged) return await navigateTo('/')

	if (!requiresAuth && isLogged && !to.meta?.global) {
		const params = { role: auth.value?.role || 'user' }
		return await navigateTo({ name: 'role-home', params })
	}
})
