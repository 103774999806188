import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

type Plugin = { $dayjs: typeof dayjs }

export default defineNuxtPlugin<Plugin>({
	name: 'dayjs:config',
	dependsOn: ['dayjs:dynamic-locales'],
	parallel: true,
	async setup(nuxt) {
		// Check and add utc plugin
		if (!dayjs.utc) dayjs.extend(utc)

		await nuxt.hooks.callHook('dayjs:config', dayjs)
		nuxt.provide('dayjs', dayjs)
	},
})
