import { useSocketIO, toRefs, reactive, onMounted, onBeforeUnmount } from "#imports";
import { getUid } from "./parseNameFromInstance.js";
import { configKey } from "./constants.js";
import { defineStore, acceptHMRUpdate } from "pinia";
export const store = defineStore(configKey, () => {
  const io = useSocketIO();
  const state = reactive({
    id: io.id,
    value: /* @__PURE__ */ new Map(),
    transport: void 0,
    status: {
      active: false,
      pending: false,
      connected: false,
      error: null
    }
  });
  const actions = {
    connect: () => {
      if (io.connected) return;
      state.status.pending = true;
      io.connect();
    },
    on: (event, listener, component) => {
      component = getUid(event, component);
      if (!state.value.has(event)) state.value.set(event, /* @__PURE__ */ new Set());
      const socket = io.on(event, listener);
      state.value?.get(event)?.add(component);
      return () => {
        socket.off(event, listener);
        if (component) state.value?.get(event)?.delete(component);
      };
    },
    off: (event) => {
      io.off(event);
      state.value.delete(event);
    },
    setup: (event, listener, component) => {
      component = getUid(event, component);
      if (!state.value.has(event)) state.value.set(event, /* @__PURE__ */ new Set());
      onMounted(() => {
        io.on(event, listener);
        if (component) state.value?.get(event)?.add(component);
      });
      onBeforeUnmount(() => {
        io.off(event, listener);
        const name = state.value.get(event);
        if (component) name?.delete(component);
      });
      return () => {
        io.off(event, listener);
        if (component) state.value?.get(event)?.delete(component);
      };
    },
    emit: (event, ...args) => io.emit(event, ...args)
  };
  return {
    ...toRefs(state),
    ...actions
  };
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
}
