export default defineNuxtPlugin({
	name: 'vuetify:config',
	parallel: true,
	async setup(nuxt) {
		const { cspNonce } = useCsp()
		const time = useTimeStore()

		nuxt.hook('vuetify:ready', (vuetify) => {
			const { defaults, date } = vuetify

			watch(() => time.weekStart, (value) => {
				defaults.value ||= {}
				defaults.value.global ||= {}
				defaults.value.global.firstDayOfWeek = value
				if ('weekStart' in date.instance) date.instance.weekStart = value
			})
		})

		extendVuetifyConfig((options) => {
			// Defaults
			options.defaults ||= {}
			options.defaults.global ||= {}
			options.defaults.global.firstDayOfWeek = time.weekStart

			// Theme
			options.theme ||= {}
			options.theme.cspNonce = cspNonce

			// Date
			options.date ||= {}
			options.date.adapter = createDateAdapter({
				locale: options.locale?.locale,
				weekStart: time.weekStart,
				timeZone: time.timeZone,
			})
		})
	},
})
