const key = 'prompt'
export const usePromptStore = defineStore(key, () => {
	// State
	const state = reactive<State>({
		value: new Map(),
	})

	// Getters
	const getters = {
		length: computed(() => state.value.size),
		isActive: computed(() => {
			for (const value of state.value.values()) {
				if (value.active === true) return true
			}
			return false
		}),
	}

	// Action
	const actions: Actions = {
		create: (options) => {
			const id = options?.id ?? crypto.randomUUID()
			options.id = id
			options.active = true
			const close = options.close

			options.close = () => {
				if (typeof close === 'function') close()
				const item = state.value.get(id)
				if (item) item.active = false
				setTimeout(() => state.value.delete(id), 200)
			}

			if (options.button) {
				const action = options?.button?.action
				// options.button.color = options.button.color ?? 'primary'

				options.button.action = (args) => {
					return typeof action === 'function'
						? action(args)
						: options?.close!()
				}
			}

			state.value.set(id, options)
			return options
		},
		close: (id) => {
			state.value.get(id)?.close!()
		},
	}

	return {
		...toRefs(state),
		...getters,
		...actions,
	}
})

interface State {
	value: Map<string, Prompt>
}

interface Actions {
	create(options: Prompt): Prompt
	close(id: string): void
}
